var moment = require('moment');
import objectFitImages from 'object-fit-images';
import Flickity from 'flickity';

(function ($) {
	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Starter = {
		// All pages
		'common': {
			init: function () {

				// Assign Breakpoint Bodyclasses
				function assign_bootstrap_mode() {
					let width = $(window).width();
					var mode = '';
					if (width < 768) {
						mode = "mode-xs";
					}
					else if (width < 992) {
						mode = "mode-sm";
					}
					else if (width < 1200) {
						mode = "mode-md bigshot";
					}
					else if (width > 1200) {
						mode = "mode-lg bigshot";
					}
					$("body").removeClass("mode-xs").removeClass("mode-sm").removeClass("mode-md").removeClass("mode-lg").removeClass("bigshot").addClass(mode);
				}
				assign_bootstrap_mode();
				$(window).on('resize', function () {
					assign_bootstrap_mode();
				});

				//Dateselector
				if ($('.bookingsform').length) {

					function isWithinPeakSeason(date) {
						var formattedDate = moment(date).format('YYYY-MM-DD');
						var result = false;

						$(window.peakSeasons).each(function (index, season) {
							//console.log(formattedDate, season);
							if (formattedDate >= season.start_date && formattedDate < season.end_date) {
								result = true;
							}
						});

						//console.log('isWithinPeakSeason', result);

						return result;
					};


					$('.date-pickup').each(function () {
						var $that = $(this);

						$(this).datepicker({
							language: 'nl',
							firstDay: 1,
							position: 'right center',
							minDate: new Date(),
							autoClose: true,
							dateFormat: 'dd/mm/yy',
							onRenderCell: function (date) {
								var formattedDate = moment(date).format("DD-MM-YYYY");
								var value = window.pickupDates[formattedDate];
								var disabled = value != 1;

								return {
									classes: '',
									disabled: disabled
								};
							},
							onSelect: function (formattedDate, date) {
								var d = date;
								var dateFormatted = formattedDate;

								var $dateDropoffInput = $that.parents('form').find('.date-dropoff');
								var dateDropoff = $dateDropoffInput.datepicker().data('datepicker');

								$dateDropoffInput.attr('placeholder', dateFormatted);
								dateDropoff.update('minDate', d);

								var minDate = date;

								if (minDate) {

									if (isWithinPeakSeason(minDate)) {
										minDate.setDate(minDate.getDate() + 7);
									}

									dateDropoff.update('minDate', minDate);

									var date = moment(minDate).format("DD/MM/YY");

									$dateDropoffInput.attr('placeholder', date);

								}
							},
						});
					});

					$('.date-pickupselector').on('click', function () {
						var datePickup = $(this).find('.date-pickup').datepicker().data('datepicker');
						datePickup.show();
					});

					$('.date-dropoff').each(function () {
						$(this).datepicker({
							language: 'nl',
							firstDay: 1,
							position: 'right center',
							minDate: new Date(),
							autoClose: true,
							dateFormat: 'dd/mm/yy',

							onRenderCell: function (date) {
								var formattedDate = moment(date).format("DD-MM-YYYY");
								var value = window.returnDates[formattedDate];
								var disabled = value != 1;

								return {
									classes: '',
									disabled: disabled
								};
							},
						});
					});


					$('.date-dropoffselector').on('click', function () {
						var dateDropoff = $(this).find('.date-dropoff').datepicker().data('datepicker');
						dateDropoff.show();
					});

					function repositionDatepicker() {
						$('.date-pickup').each(function () {
							var datepicker = $(this).datepicker().data('datepicker');
							datepicker.update({
								position: 'bottom center'
							});
						});
						$('.date-dropoff').each(function () {
							var datepicker = $(this).datepicker().data('datepicker');
							datepicker.update({
								position: 'bottom center'
							});
						});
					}

					if ($('body').hasClass('mode-xs')) {
						repositionDatepicker();
					}

					$(window).resize(function () {
						if ($('body').hasClass('mode-xs')) {
							repositionDatepicker();
						}
						else {
							$('.date-pickup').each(function () {
								var datepicker = $(this).datepicker().data('datepicker');
								datepicker.update({
									position: 'right center'
								})
							});
							$('.date-dropoff').each(function () {
								var datepicker = $(this).datepicker().data('datepicker');
								datepicker.update({
									position: 'right center'
								})
							});
						};
					});

				}



				if (!$('body').hasClass('boeking')) {
					if ($('html').attr('lang') == 'en-US') {
						// $('.gform_fields .ginput_container_date .datepicker').each(function () {
						// 	$(this).datepicker().data('datepicker').destroy();
						// 	// console.log(datepicker);
						// 	datepicker.update({
						// 		language: 'en',
						// 		dateFormat: 'dd/mm/yyyy',
						// 		autoClose: true,
						// 		minDate: new Date()
						// 	})
						// });
					} else if ($('html').attr('lang') == 'es-ES') {
						// $('.gform_fields .ginput_container_date .datepicker').each(function(){
						// 	var datepicker = $(this).datepicker().data('datepicker');
						// 	datepicker.update({
						// 	    language: 'es',
						// 	    dateFormat: 'dd/mm/yyyy',
						// 	    autoClose: true,
						// 	    minDate: new Date()
						// 	})
						// });
					} else {
						// $('.gform_fields .ginput_container_date .datepicker').each(function () {
						// 	var datepicker = $(this).datepicker().data('datepicker');
						// 	datepicker.update({
						// 		language: 'nl',
						// 		dateFormat: 'dd/mm/yyyy',
						// 		autoClose: true,
						// 		minDate: new Date()
						// 	})
						// });
					}
				};



				//Personselector
				$('.selectpicker').selectpicker({
				});

				$('.gfield_select').selectpicker({
				});

				//Flickity
				const blockReviews = document.querySelectorAll('.block--reviews');
				blockReviews.forEach((element) => {
					new Flickity(element, {
						cellAlign: 'center',
						contain: true,
						prevNextButtons: false,
						wrapAround: true
					});
				});

				if ($('body').hasClass('page-template-template-contact')) {
					if ($('body').hasClass('mode-sm') || $('body').hasClass('mode-xs')) {
					} else {
						$('.block__half').matchHeight();
					}
				}

				if ($('body').hasClass('single-van')) {
					if ($('body').hasClass('mode-sm') || $('body').hasClass('mode-xs')) {
					} else {
						var sellImages = $('#sell_images').outerHeight();
						var sellHelp = $('#sell_help').outerHeight();
						var totalHeight = sellImages + sellHelp + 30;
						$('#sell_data').height(totalHeight);
					}
				}

				var $grid = $('.grid').imagesLoaded(function () {
					$grid.isotope({
						itemSelector: '.photopage_photo',
						percentPosition: true,
						masonry: {
							columnWidth: '.grid-sizer'
						}
					});
				});

				$('.photopage_photo').each(function () {
					var $el = $(this),
						pswpElement = $('.pswp')[0];

					$el.on('click', function (e) {
						var dataIndex = $el.attr('data-index');
						var options = {
							getThumbBoundsFn: false,
							index: dataIndex,
							showHideOpacity: true,
							history: false,
							shareEl: false,
							closeOnVerticalDrag: false,
							errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">De afbeelding</a> kon niet worden geladen.</div>'
						};
						var galleryImages = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, window['gallery'], options);
						galleryImages.init();
						e.preventDefault();
					});
				});

				var $sellgrid = $('.sellgrid').imagesLoaded(function () {
					$sellgrid.isotope({
						itemSelector: '.block__sell',
						percentPosition: true,
						layoutMode: 'fitRows'
						// masonry: {
						// 	columnWidth: '.grid-sizer',
						// }
					});
				});

				// filter items on button click
				$('.filter-button-group li').on('click', function () {
					var filterValue = $(this).attr('data-filter');
					$grid.isotope({ filter: filterValue });
					$sellgrid.isotope({ filter: filterValue });
				});


				//Photoswipe
				$('.block--gallery__image').each(function () {
					var $el = $(this),
						pswpElement = $('.pswp')[0];

					$el.on('click', function (e) {
						var dataIndex = $el.attr('data-index');
						var options = {
							getThumbBoundsFn: false,
							index: dataIndex,
							showHideOpacity: true,
							history: false,
							shareEl: false,
							closeOnVerticalDrag: false,
							errorMsg: '<div class="pswp__error-msg"><a href="%url%" target="_blank">De afbeelding</a> kon niet worden geladen.</div>'
						};
						var galleryImages = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, window['gallery'], options);
						galleryImages.init();
						e.preventDefault();
					});
				});

				//Menu hoverintent
				$(".menu li").hoverIntent({
					over: function () { $(this).addClass('submenu-active'); },
					out: function () { $(this).removeClass('submenu-active'); },
					timeout: 300
				});

				//Videoplay
				$('[data-videoplay]').on('click', function () {
					$('.video-dimmer').addClass('dimmer-active');
				});
				$('.video-dimmer').on('click', function () {
					$('.video-dimmer').removeClass('dimmer-active');
					var video = $(".video-popup iframe").attr("src");
					$(".video-popup iframe").attr("src", "");
					$(".video-popup iframe").attr("src", video);
				});

				$('#langpicker').on('changed.bs.select', function (e) {
					var url = $(this).val();
					if (url) {
						window.location = url;
					}
					return false;
				});

				//Mobile menu
				$('.mobile-menu__button').on('click', function () {
					if ($(this).hasClass('menubtn-active')) {
						$(this).removeClass('menubtn-active');
						$('.mobile-menu').removeClass('mobile-menu--active');
						$('.mobile-menu').css('max-height', '0px');
					} else {
						$(this).addClass('menubtn-active');
						$('.mobile-menu').addClass('mobile-menu--active');
						var menuHeight = $('.menu-mobile-container').outerHeight() + 100;
						$('.mobile-menu').css('max-height', menuHeight);
					}
				});

				//Objectfit
				objectFitImages();

				if (typeof gform != 'undefined') {
					gform.addFilter('gform_product_total', function (total) {

						$('.bookingprice__total').text(gformFormatMoney(total));

						$('#bookingprice-camper-type').siblings().remove();

						var $list = $('#bookingprice-camper-type').closest('ul');

						$('#input_2_24').find('input:checked').each(function (index, elm) {
							var $label = $(elm).parent().children('label');

							var text = $label.clone()
								.children() //select all the children
								.remove()   //remove all the children
								.end()  //again go back to selected element
								.text();

							var $li = $('<li>').text(text);

							$list.append($li);

						});
						return total;
						gformCalculateTotalPrice("2");
					}, 99);

				}


				if (typeof gformCalculateTotalPrice != 'undefined' && $('#gform_2').length) {
					gformCalculateTotalPrice("2");
				}


				// Whitelabel Modal
				const $whitelabelModal = $('#whitelabel-download-modal');
				if ($whitelabelModal.length > 0) {

					var modalTitle = $whitelabelModal.attr('data-title');

					var modalCookie = Cookies.set('modal-' + modalTitle);
					if (modalCookie != 'shown') {
						setTimeout(function () {
							$whitelabelModal.modal('show');
							modalCookie = Cookies.set('modal-' + modalTitle, 'shown');
							console.log('modalCookie', modalCookie);
						}, 5000);
					}
				}

				$(document).on("gform_confirmation_loaded", function (e, form_id) {
					$whitelabelModal = $('#whitelabel-download-modal');
					var whitelabelForm = $whitelabelModal.find('#gf_'.form_id);
					if (whitelabelForm) {
						var modalDownload = $whitelabelModal.attr('data-download');
						if (modalDownload) {
							$whitelabelModal.modal('hide');
							window.open(modalDownload);
						}
					}
				});


			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Starter;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
